<template>
  <div>
    <div v-if="table">
      <game-table v-if="this.adminAccess=='Super Admin' || this.adminAccess=='Event Support Staff' || this.adminAccess=='Sales Representative'"></game-table>
       <game-host-table v-if="this.adminAccess=='game'"></game-host-table>
    </div>
    <div v-else>
      <game-bulk-import></game-bulk-import>
    </div>
  </div>
</template>
<script>
export default {
  name: "GameManagement",
  components: {
    GameTable: () => import("./GameTable/GameTable"),
    GameHostTable: () => import("./GameTable/GameHostTable"),
    GameBulkImport: () => import("./GameBulkImport/GameBulkImport"),
  },
  data() {
    return {
       adminAccess: localStorage.getItem("adminAccess"),
      table: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeCondition(value) {
      this.table = value;
      this.$root.$refs.adminLayout.setGameBulk(!value)
    },
    
  },
  mounted() {},
  created() {
    this.$root.$refs.GameManagement = this;
  },
};
</script>
